import { ThunkAction } from "redux-thunk";
import { RootState } from './reducer';
import { Action } from 'redux';

import { initialFormData } from './form/actions';
import CertificateAppealService from 'src/services/CertificateAppeal​/CertificateAppealService';

// import { toggleVerificationLoader, setUserInfo } from './system/actions';

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;


export function getId(ID: String): ThunkResult<Promise<any>> {
	return async (dispatch) => {

		const certificateAppealService = new CertificateAppealService();
		const result = await certificateAppealService.GetId(ID);
		dispatch(initialFormData(result));

		return result;
	}
}


