import React, { Component } from "react"
import { connect } from "react-redux"

import { RootState } from "../../store/reducer"
import { Dispatch, bindActionCreators } from "redux"
import { setFormData, setFormErrors } from "src/store/form/actions"

import { getId } from "../../store/thunkCertificateAppeal"
import { castThunkAction } from "src/helpers/casting"
import Hotel from "./newDesgins/hotel"

interface OwnProps {
  certificateGuid: string
}

interface OwnState {
  lang: boolean
  responsefinish: boolean
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        getId,
      },
      dispatch
    ),
  }
}

type MainPageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

class MainPage extends Component<MainPageProps, OwnState> {
  state = {
    lang: true,
    responsefinish: false,
  }

  UNSAFE_componentWillMount() {
    if (this.props.certificateGuid) {
      castThunkAction(this.props.getId(this.props.certificateGuid)).then(
        (response: any) => {
          if (response.errorCode !== 0 || response.errorMessage !== "") {
            window.location.href = "/Error/ErrorNotFound"
          } else {
            this.setState({ responsefinish: true })
          }
        }
      )
    }
  }

  transport() {
    const { formData } = this.props
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? "  CERTIFIED  " : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">
              {formData?.data?.transport?.confirmationDateString}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Owner of the Vehicle" : "Belge Sahibi"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.transport?.companyName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certificate Number" : "Sertifika No"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.transport?.certificateNo}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Category" : "Türü"} <span>:</span>{" "}
            </section>
            <section className="right">
              {this.state.lang
                ? "Tour and Transfer Vehicles"
                : "Tur ve Transfer Araçları"}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang
                ? "Vehicle Registration Number Plate"
                : "Plaka Numarası"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.transport?.vehiclePlaque}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang
                ? "Registered Licensee of Vehicle"
                : "Aracın Kayıtlı Olduğu Yetki Belgesi"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.transport?.vehicleDocumentTypeName}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Seat Capacity" : "Koltuk Sayısı "}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.transport?.seatCount}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Vehicle Type" : "Araç Cinsi"} <span>:</span>{" "}
            </section>
            <section className="right">
              {" "}
              {this.state.lang
                ? formData?.data?.transport?.vehicleTypeName_En
                : formData?.data?.transport?.vehicleTypeName}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.transport?.companyUserTitle}
            </section>
          </section>
        </aside>

        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              {formData?.data?.transport?.companyUserTitle}{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
  loyover() {
    const { formData } = this.props
    return <Hotel data={formData} lang={this.state.lang} />
  }
  restaurant() {
    const { formData } = this.props
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? "  CERTIFIED  " : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">
              {formData?.data?.restaurant?.confirmationDateString}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Facility Name" : "Tesis"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.restaurant?.brandName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certificate Number" : "Sertifika No"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.restaurant?.certificateNo}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Category" : "Tesis Türü"} <span>:</span>{" "}
            </section>
            <section className="right">
              {this.state.lang ? "Restaurant / Cafe" : "Restoran / Kafe"}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Location" : "Konum"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.restaurant?.cityName}/
              {formData?.data?.restaurant?.districtName}{" "}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Company Name" : "İşletme Sahibi"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.restaurant?.companyName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.restaurant?.companyUserTitle}
            </section>
          </section>
        </aside>
        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              {formData?.data?.restaurant?.companyUserTitle}{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
  congress() {
    const { formData } = this.props
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? "  CERTIFIED  " : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">
              {formData?.data?.congress?.confirmationDateString}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Facility Name" : "Tesis"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.congress?.brandName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certificate Number" : "Sertifika No"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.congress?.certificateNo}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Location" : "Konum"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.congress?.cityName}/
              {formData?.data?.congress?.districtName}{" "}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Company Name" : "İşletme Sahibi"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.congress?.companyName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.congress?.companyUserTitle}
            </section>
          </section>
        </aside>
        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              {formData?.data?.congress?.companyUserTitle}{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
  sea() {
    const { formData } = this.props
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? "  CERTIFIED  " : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">
              {formData?.data?.seaTourism?.confirmationDateString}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Facility Name" : "Tesis"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.seaTourism?.brandName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certificate Number" : "Sertifika No"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.seaTourism?.certificateNo}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Location" : "Konum"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.seaTourism?.cityName}/
              {formData?.data?.seaTourism?.districtName}{" "}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Company Name" : "İşletme Sahibi"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.seaTourism?.companyName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.seaTourism?.companyUserTitle}
            </section>
          </section>
        </aside>
        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              {formData?.data?.seaTourism?.companyUserTitle}{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
  themed() {
    const { formData } = this.props
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? "  CERTIFIED  " : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">
              {formData?.data?.themedFacility?.confirmationDateString}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Facility Name" : "Tesis"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.themedFacility?.brandName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certificate Number" : "Sertifika No"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.themedFacility?.certificateNo}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Location" : "Konum"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.themedFacility?.cityName}/
              {formData?.data?.themedFacility?.districtName}{" "}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Company Name" : "İşletme Sahibi"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.themedFacility?.companyName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.themedFacility?.companyUserTitle}
            </section>
          </section>
        </aside>
        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              {formData?.data?.themedFacility?.companyUserTitle}{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
  winterTourism() {
    const { formData } = this.props
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? "  CERTIFIED  " : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">
              {formData?.data?.winterTourism?.confirmationDateString}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Facility Name" : "Tesis"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.winterTourism?.brandName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certificate Number" : "Sertifika No"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.winterTourism?.certificateNo}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Location" : "Konum"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.winterTourism?.cityName}/
              {formData?.data?.winterTourism?.districtName}{" "}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Company Name" : "İşletme Sahibi"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.winterTourism?.companyName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.winterTourism?.companyUserTitle}
            </section>
          </section>
        </aside>
        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              {formData?.data?.winterTourism?.companyUserTitle}{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
  airport() {
    const { formData } = this.props
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? " CERTIFIED" : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">
              {formData?.data?.airport.confirmationDateString}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Name of Airport" : "Havalimanı Adı"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {this.state.lang
                ? formData?.data?.airport?.brandName_En
                : formData?.data?.airport?.brandName}{" "}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certificate Number" : "Sertifika No"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.airport?.certificateNo}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Category" : "Tesis Türü"} <span>:</span>{" "}
            </section>
            <section className="right">
              {this.state.lang ? "Airport" : "Havaalanı"}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Location" : "Konum"} <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.airport?.cityName}/
              {formData?.data?.airport?.districtName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Company Name" : "İşletme Sahibi"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.airport?.companyName}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              {formData?.data?.airport?.companyUserTitle}
            </section>
          </section>
        </aside>
        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              {formData?.data?.airport?.companyUserTitle}{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
  render() {
    const { formData } = this.props
    const { responsefinish } = this.state

    return responsefinish && formData?.data?.transport ? (
      this.transport()
    ) : formData?.data?.hotel || formData?.data?.greenHotel ? (
      this.loyover()
    ) : formData?.data?.restaurant ? (
      this.restaurant()
    ) : formData?.data?.airport ? (
      this.airport()
    ) : formData?.data?.congress ? (
      this.congress()
    ) : formData?.data?.seaTourism ? (
      this.sea()
    ) : formData?.data?.themedFacility ? (
      this.themed()
    ) : formData?.data?.winterTourism ? (
      this.winterTourism()
    ) : (
      <div></div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
