import React, { useEffect, useState } from "react"
import { getCertificateStatus, getGreenLogo } from "./enums"
import "./styles.css"

export default function Hotel(data: any) {
  const [lang, setLang] = useState(true)
  const hotelData = data.data.data.hotel
  const greenHotelData = data.data.data.greenHotel
  const [activeTab, setactiveTab] = useState(greenHotelData ? "green" : "safe")

  // console.log("hotelData", hotelData);
  // console.log("greenHotelData", greenHotelData);

  useEffect(() => {
    const certificateInfoLineDiv = document.querySelectorAll(
      ".certificate-info__line"
    )
    certificateInfoLineDiv.forEach((el: any) => {
      el.style.backgroundColor = activeTab === "safe" ? "#e8f4f5" : "#bfe3b4"
    })
  }, [activeTab])

  const getFooter = () => {
    return (
      <div className="certificate-footer">
        <span className="certificate-footer__label">
          {lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}
        </span>
        <span className="certificate-footer__note">
          {lang
            ? " This verify/certificate has been issued by " +
              (activeTab === "safe"
                ? hotelData?.companyUserTitle
                : greenHotelData?.companyUserTitle) +
              " under the authority of Turkish Ministry of Culture & Tourism."
            : "Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş " +
              (activeTab === "green"
                ? hotelData?.companyUserTitle
                : greenHotelData?.companyUserTitle) +
              " tarafından verilmiştir."}
        </span>
      </div>
    )
  }
  const getHotelContent = () => {
    return (
      <div className="certificates__safe-tourism-certificate">
        <div className="certificate-info">
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Issue Date" : "Sertifika Düzenleme Tarihi"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {hotelData?.confirmationDateString}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Expiry Date" : "Sertifika Geçerlilik Tarihi"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {hotelData?.extendedClosedDateString}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Facility Name" : "Tesis Adı"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {hotelData?.brandName}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Certificate Number" : "Sertifika Numarası"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {hotelData?.certificateNo}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Category" : "Tesis Kategorisi"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {lang
                ? hotelData?.layoverSubCategoryName_En
                : hotelData?.layoverSubCategoryName}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Location" : "İl / İlçe"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {hotelData?.cityDistrictName}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Company Name" : "Belge Sahibi / Ticari Ünvan"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {hotelData?.companyName}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Certified by" : "Denetim Firması"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {hotelData?.companyUserTitle}
            </span>
          </div>
        </div>
      </div>
    )
  }
  const getGreenHotelContent = () => {
    return (
      <div className="certificates__green-certificate">
        <div className="certificate-info">
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Issue Date" : "Sertifika Düzenleme Tarihi"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {greenHotelData?.confirmationDateString}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Expiry Date" : "Sertifika Geçerlilik Tarihi"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {greenHotelData?.extendedClosedDateString}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang
                ? "Certificate/Status"
                : "Sürdürülebilir Turizm Sertifika / Belge Durumu"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {getCertificateStatus(lang, greenHotelData?.status)}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Facility Name" : "Tesis Adı"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {greenHotelData?.brandName}
            </span>
          </div>

          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang
                ? "Certificate / Verification Number"
                : "Sertifika/Belge Numarası"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {greenHotelData?.certificateNo}
            </span>
          </div>

          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Category" : "Tesis Kategorisi"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {lang
                ? greenHotelData?.layoverSubCategoryName_En
                : greenHotelData?.layoverSubCategoryName}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Location" : "İl / İlçe"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {greenHotelData?.cityDistrictName}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Company Name" : "Belge Sahibi / Ticari Ünvan"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {greenHotelData?.companyName}
            </span>
          </div>
          <div className="certificate-info__line">
            <span className="certificate-info__label">
              {lang ? "Certified by" : "Denetim Firması"}
            </span>
            <span className="certificate-info__colon">:</span>
            <span className="certificate-info__value">
              {greenHotelData?.companyUserTitle}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const getHotelCertificate = () => {
    return (
      <div className="container">
        <div className="certificate safe-tourism-certificate">
          <div className="certificate-heading safe-tourism-certificate__heading">
            {lang ? "SAFE TOURISM" : "GÜVENLİ TURİZM"}
          </div>
          <div className="certificate-logo">
            <img
              src="/image/safe-certificate.svg"
              alt="Safe Tourism Logo"
              width="140"
            />
          </div>
          {getHotelContent()}
        </div>
        {getFooter()}
      </div>
    )
  }
  const getGreenCertificate = () => {
    return (
      <div className="container">
        <div className="certificate green-certificate">
          <div className="certificate-heading green-certificate__heading">
            {lang ? "SUSTAINABLE TOURISM" : "SÜRDÜRÜLEBİLİR TURİZM"}
          </div>
          <div className="certificate-logo">
            <img
              src={getGreenLogo(lang, greenHotelData?.status)}
              alt="Safe Tourism Logo"
              width="140"
            />
          </div>
          {getGreenHotelContent()}
        </div>
        {getFooter()}
      </div>
    )
  }
  const getTwins = () => {
    return (
      <div className="container">
        <div className="certificates">
          <div className="certificates-button-group">
            <button
              className={`certificates-button certificates-safe-tourism-button ${
                activeTab === "green" && "certificates-button__disabled"
              }`}
              id="certificates-safe-tourism-button"
              onClick={() => setactiveTab("safe")}
            >
              <span>{lang ? "SAFE TOURISM" : "GÜVENLİ TURİZM"}</span>
            </button>
            <span className="certificates-line"></span>
            <button
              className={`certificates-button certificates-green-tourism-button ${
                activeTab === "safe" && "certificates-button__disabled"
              }`}
              id="certificates-green-tourism-button"
              onClick={() => setactiveTab("green")}
            >
              <span>
                {lang ? "SUSTAINABLE TOURISM" : "SÜRDÜRÜLEBİLİR TURİZM"}
              </span>
            </button>
          </div>
          <div className="certificates-logo-group">
            <img
              className={
                activeTab === "green" ? "certificates-logo-transparent" : ""
              }
              src="/image/safe-certificate.svg"
              alt="Safe Tourism Logo"
              style={{ width: "100px" }}
            />
            <img
              className={
                activeTab === "safe" ? "certificates-logo-transparent" : ""
              }
              src={getGreenLogo(lang, greenHotelData?.status)}
              alt="Green Certificate Logo"
              style={{ width: "100px" }}
            />
          </div>
          {activeTab === "safe" ? getHotelContent() : getGreenHotelContent()}
        </div>
        {getFooter()}
      </div>
    )
  }

  const createHtml = () => {
    // console.log("createHtml")
    if (hotelData && greenHotelData) {
      //green ve safe sertifikası var ise
      return getTwins()
    } else {
      if (hotelData) {
        //sadece safe serttifikası var ise
        return getHotelCertificate()
      } else if (greenHotelData) {
        //sadece green sertifikası var ise
        return getGreenCertificate()
      } else {
        //ikisi de yok ise
        return <b>Certificate Not Found</b>
      }
    }
    return <></>
  }

  return (
    <>
      <button className="languageButton" onClick={(x) => setLang(!lang)}>
        {lang ? "TR" : "EN"}
      </button>{" "}
      {createHtml()}
    </>
  )
}
