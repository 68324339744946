export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';

export type ModalNames = "";

interface ToggleModalAction {
	type: typeof TOGGLE_MODAL;
	id: ModalNames;
	data: any;
}

interface SetModalDataAction {
	type: typeof SET_MODAL_DATA;
	id: ModalNames;
	data: any;
}

// interface ModalInnerState<T> {
// 	show: boolean;
// 	data: T;
// }

export interface ModalState {
}

export type ModalActionTypes = ToggleModalAction | SetModalDataAction;