
export enum MessageType {
	SUCCESS = 1,
	WARNING = 2,
	ERROR = 3
}

export interface SystemStoreMessage {
	show: boolean;
	messageTitle?: string;
	messageContent: string;
	messageType?: MessageType;
}

export interface SystemStore {
	message: SystemStoreMessage;
	verificationLoading: boolean;
	language: string;
	userInfo: any;
	loading: boolean;
	tabs: TabItem[];
	advancedMode: boolean;
	leftPanel: string;
	tokenStatus: boolean;
	toggleConfirm: {
		show: boolean;
		callback: () => any;
	};
}

export enum TabTypeEnum {
	TABLE = 1,
	QUERY = 2,
	DIAGRAM = 3,
	QUERY_BUILDER = 4
}

export interface TabItem {
	id: string;
	tabType: TabTypeEnum;
	active: boolean;
	datasourceId: string | null;
	reference?: any;
}

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const TOGGLE_ADVANCED = 'TOGGLE_ADVANCED';
export const TOGGLE_VERIFICATION_LOADER = 'TOGGLE_VERIFICATION_LOADER';
export const SET_LEFT_PANEL = 'SET_LEFT_PANEL';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const OPEN_TAB = 'OPEN_TAB';
export const CLOSE_TAB = 'CLOSE_TAB';
export const CLOSE_ALL_TAB = 'CLOSE_ALL_TAB';
export const TOGGLE_CONFIRM = "TOGGLE_CONFIRM";
export const TOKEN_CONTROL = "TOKEN_CONTROL";

interface ShowMessageAction {
	type: typeof SHOW_MESSAGE;
	content: string;
	messageType?: MessageType;
	title?: string;
}

interface HideMessageAction {
	type: typeof HIDE_MESSAGE;
}

interface ToggleLoaderAction {
	type: typeof TOGGLE_LOADER;
}

interface ToggleAdvancedAction {
	type: typeof TOGGLE_ADVANCED;
}

interface ToggleVerificationLoaderAction {
	type: typeof TOGGLE_VERIFICATION_LOADER;
}

interface SetLanguageAction {
	type: typeof SET_LANGUAGE;
	language: string;
}

interface SetLeftPanel {
	type: typeof SET_LEFT_PANEL;
	leftPanel?: string;
}

interface SetUserInfo {
	type: typeof SET_USER_INFO;
	userInfo: any;
}

interface OpenTabAction {
	type: typeof OPEN_TAB;
	id: string;
	tabType: TabTypeEnum;
	datasourceId: string | null;
	reference: any;
}

interface CloseTabAction {
	type: typeof CLOSE_TAB;
	id: string;
	tabType: TabTypeEnum;
}

interface CloseAllTabAction {
	type: typeof CLOSE_ALL_TAB;
}

interface ToggleConfirmAction {
	type: typeof TOGGLE_CONFIRM;
	show: boolean;
	callback: () => any;
}

interface TokenControlAction {
	type: typeof TOKEN_CONTROL;
	tokenStatus: boolean;
}

export type SystemActionTypes = ToggleConfirmAction | TokenControlAction | ShowMessageAction | HideMessageAction | SetLeftPanel | ToggleLoaderAction | SetLanguageAction | ToggleVerificationLoaderAction | SetUserInfo | CloseTabAction | CloseAllTabAction | OpenTabAction | ToggleAdvancedAction;