import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { initializeIcons } from "@uifabric/icons";

import HomeRoute from "./routes/HomeRoute";
// import TgaRoute from "./routes/TgaRoute";
// import TgaaRoute from "./routes/TgaRoutea";
// import TgabRoute from "./routes/TgaRouteb";
// import TgacRoute from "./routes/TgaRoutec";
// import TgadRoute from "./routes/TgaRouted";
// import TgafRoute from "./routes/TgaRoutef";
import ErrorNotFound from "./routes/ErrorNotFound";
// import Components from "./components/Components";

initializeIcons();

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
             <Route exact path="/" component={ErrorNotFound} /> 
            <Route
              exact
              path="/sertifika/:certificateGuid"
              component={HomeRoute}
            />
           <Route
              exact
              path="/Error/ErrorNotFound"
              component={ErrorNotFound}
            />
            {/* <Redirect to="/Error/ErrorNotFound" /> */}
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
