
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { MainPage } from 'src/components/Main';

export default class HomeRoute extends React.Component<RouteComponentProps<any>> {
	render() {
		return (
			<React.Fragment>
				<MainPage certificateGuid={this.props.match.params.certificateGuid} />
			</React.Fragment>
		);
	}
}
