import { SystemStore, SystemActionTypes, SHOW_MESSAGE, MessageType, HIDE_MESSAGE, TOGGLE_LOADER, SET_LANGUAGE, TOGGLE_VERIFICATION_LOADER, SET_USER_INFO, OPEN_TAB, CLOSE_TAB, CLOSE_ALL_TAB, TOGGLE_ADVANCED, SET_LEFT_PANEL, TOGGLE_CONFIRM, TOKEN_CONTROL } from "./types";
import _ from "lodash";

const initialState: SystemStore = {
	loading: false,
	verificationLoading: false,
	userInfo: {},
	message: {
		show: false,
		messageContent: ""
	},
	language: "en",
	advancedMode: true,
	tabs: [],
	leftPanel: "",
	tokenStatus: false,
	toggleConfirm: {
		show: false,
		callback: () => { }
	}
};

export function systemReducer(state = initialState, action: SystemActionTypes): SystemStore {
	switch (action.type) {
		case SHOW_MESSAGE:
			return {
				...state,
				message: {
					show: true,
					messageContent: action.content,
					messageTitle: action.title,
					messageType: action.messageType || MessageType.SUCCESS
				}
			};
		case HIDE_MESSAGE:
			return {
				...state,
				message: {
					show: false,
					messageContent: "",
				}
			};
		case TOGGLE_LOADER:
			return {
				...state,
				loading: !state.loading
			};
		case TOGGLE_ADVANCED:
			return {
				...state,
				advancedMode: !state.advancedMode
			};
		case SET_USER_INFO:
			return {
				...state,
				userInfo: action.userInfo
			};
		case SET_LEFT_PANEL:
			return {
				...state,
				leftPanel: action.leftPanel || ""
			}
		case TOGGLE_VERIFICATION_LOADER:
			return {
				...state,
				verificationLoading: !state.verificationLoading
			};
		case SET_LANGUAGE:
			return {
				...state,
				language: action.language
			};
		case OPEN_TAB: {
			const tabs = _.cloneDeep(state.tabs);
			const foundIndex = tabs.findIndex(x => x.id === action.id && x.tabType === action.tabType);

			if (foundIndex >= 0) {
				tabs[foundIndex].active = true;
			} else {
				tabs.push({
					active: true,
					id: action.id,
					tabType: action.tabType,
					datasourceId: action.datasourceId,
					reference: action?.reference || {}
				});
			}

			tabs.filter(x => x.id !== action.id).forEach(x => x.active = false);

			return {
				...state,
				tabs
			};
		}
		case CLOSE_TAB: {
			const tabs = _.cloneDeep(state.tabs);
			const foundIndex = tabs.findIndex(x => x.id === action.id && x.tabType === action.tabType);

			tabs.splice(foundIndex, 1);

			return {
				...state,
				tabs
			};
		};
		case CLOSE_ALL_TAB:
			return {
				...state,
				tabs: []
			};
		case TOGGLE_CONFIRM:
			return {
				...state,
				toggleConfirm: {
					show: action.show,
					callback: action.callback
				}
			};
		case TOKEN_CONTROL:
			return {
				...state,
				tokenStatus: action.tokenStatus
			}
		default:
			return state;
	}
}