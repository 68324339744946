
import { FormActionTypes, INITIAL_FORM_DATA, SET_FORM_DATA, SET_FORM_ERRORS, RESET_FORM } from "./types";

/**
 * Form Data Objesini, Direkt Olarak Obje ile Set Eder.
 */
export function initialFormData(formData: any): FormActionTypes {
	return {
		type: INITIAL_FORM_DATA,
		formData
	}
}

/**
 * Form Data Objesini, Dışarıdan Gelen Key ve Value'ya Göre Set Eder.
 */
export function setFormData(key: string, value: any): FormActionTypes {
	return {
		type: SET_FORM_DATA,
		key,
		value
	}
}

/**
 * Form Errors Objesini, Direkt Olarak Error'ları Barındıran Obje ile Set Eder.
 */
export function setFormErrors(formErrors: any): FormActionTypes {
	return {
		type: SET_FORM_ERRORS,
		formErrors
	}
}

/**
 * Form Data ve Form Errors Objelerini Temizlemeyi Set Eder.
 */
export function resetForm(): FormActionTypes {
	return {
		type: RESET_FORM
	}
}


















