import axios, { AxiosRequestConfig, AxiosInstance } from "axios";

export interface RequestConfigWithMetadata extends AxiosRequestConfig {
	metadata: {
		requestStartTimestamp: number;
	};
}

export class HttpClient {
	static _authenticatedClient?: HttpClient;
	static _client?: HttpClient;

	private axiosClient: AxiosInstance;

	post<T>(url: string, request: any): Promise<T> {
		return this.axiosClient.post(url, request).then(res => {
			return res?.data as T;
		});
	}

	get<T>(url: string): Promise<T> {
		return this.axiosClient.get(url).then(res => {
			return res?.data as T;
		});
	}

	/**
	 * Returns the HttpClient that includes Authentication header automatically.
	 */
	static getClient(): HttpClient {
		if (!this._authenticatedClient) {
			const client = new HttpClient();
			client.axiosClient = axios.create({
				withCredentials: true
			});
			this._authenticatedClient = client;
		}

		return this._authenticatedClient;
	}

	static getDefaultClient(baseURL?: string): HttpClient {
		if (!this._client) {
			const client = new HttpClient();

			client.axiosClient = axios.create({
				baseURL
			});

			this._client = client;
		}

		return this._client;
	}
}
