import React, { Component } from "react"
import { connect } from "react-redux"

import { RootState } from "../../store/reducer"
import { Dispatch, bindActionCreators } from "redux"
import { setFormData, setFormErrors } from "src/store/form/actions"

import { getId } from "../../store/thunkCertificateAppeal"

interface OwnProps {
  certificateGuid: string
}

interface OwnState {
  lang: boolean
  responsefinish: boolean
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        getId,
      },
      dispatch
    ),
  }
}

type TgaProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

class Tga extends Component<TgaProps, OwnState> {
  state = {
    lang: true,
    responsefinish: false,
  }

  UNSAFE_componentWillMount() {
    window.location.href = "/sertifika/1616d5"
  }

  render() {
    return (
      <div className="container">
        <header>
          <button
            className="languageButton"
            onClick={(x) => this.setState({ lang: !this.state.lang })}
          >
            {this.state.lang ? "TR" : "EN"}
          </button>
          <aside>
            <img src="../image/icon.svg" alt="icon" />
            <span className="info">
              {this.state.lang ? "  CERTIFIED  " : "BELGE VERİLDİ"}
            </span>
          </aside>
        </header>
        <aside>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Date of Approval" : "Onaylanma Tarihi"}{" "}
              <span>:</span>
            </section>
            <section className="right">12.06.2020</section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang ? "Owner of the Vehicle" : "Belge Sahibi"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">
              VİA ORGANİZASYON HİZM. TURZ. SAN. TİC. LTD. ŞTİ.
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Category" : "Türü"} <span>:</span>{" "}
            </section>
            <section className="right">
              {this.state.lang
                ? "Tour and Transfer Vehicles"
                : "Tur ve Transfer Araçları"}
            </section>
          </section>
          <section className="box">
            <section className="left">
              {this.state.lang
                ? "Vehicle Registration Number Plate"
                : "Plaka Numarası"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">34VT8187</section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang
                ? "Registered Licensee of Vehicle"
                : "Aracın Kayıtlı Olduğu Yetki Belgesi"}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">D2</section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Vehicle Type" : "Araç Cinsi"} <span>:</span>{" "}
            </section>
            <section className="right">
              {" "}
              {this.state.lang ? "Bus" : "Otobüs"}
            </section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Seat Capacity" : "Koltuk Sayısı "}{" "}
              <span>:</span>{" "}
            </section>
            <section className="right">48</section>
          </section>

          <section className="box">
            <section className="left">
              {this.state.lang ? "Certified by" : "Denetleyici Firma"}
              <span>:</span>{" "}
            </section>
            <section className="right">
              RoyalCert Belgelendirme ve Gözetim Hizmetleri A.Ş.
            </section>
          </section>
        </aside>

        <aside>
          <section className="infoNote">
            <span>{this.state.lang ? "INFORMATION NOTE:" : "BİLGİ NOTU:"}</span>
            <label>
              {this.state.lang
                ? "This verify/certificate has been issued by "
                : " Bu sertifika, TC Kültür ve Turizm Bakanlığı tarafından yetkilendirilmiş "}
              RoyalCert Belgelendirme ve Gözetim Hizmetleri A.Ş.{" "}
              {this.state.lang
                ? "under the authority of Turkish Ministry of Culture & Tourism."
                : " tarafından verilmiştir."}
            </label>
          </section>
        </aside>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tga)
