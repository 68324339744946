export enum hotelCertificateStatusEnum {
  All = 0,
  CertificationProcess = 300,
  CertificateApprovedStage1 = 301,
  Certified = 302,
  NotConsideredAppropriate = 303,
  LeftTheProcess = 304,
  Expired = 305,
  CertificateApprovedStage2 = 306,
}

export const getGreenLogo = (lang: any, status: any) => {
  let src = ""

  if (lang) {
    switch (status) {
      case hotelCertificateStatusEnum.CertificationProcess:
        src = "../image/belgelendirme-surecinde-en.svg"
        break
      case hotelCertificateStatusEnum.CertificateApprovedStage1:
        src = "../image/asama-1-en.png"
        break
      case hotelCertificateStatusEnum.CertificateApprovedStage2:
        src = "../image/asama-2-en.png"
        break
      case hotelCertificateStatusEnum.Certified:
        src = "../image/green-sertifikali-en.png"
        break

      default:
        src = "../image/green-ana-logo-en.png"
        break
    }
  } else {
    switch (status) {
      case hotelCertificateStatusEnum.CertificationProcess:
        src = "../image/green-ana-logo-tr.png"
        break
      case hotelCertificateStatusEnum.CertificateApprovedStage1:
        src = "../image/asama-1-tr.png"
        break
      case hotelCertificateStatusEnum.CertificateApprovedStage2:
        src = "../image/asama-2-tr.png"
        break
      case hotelCertificateStatusEnum.Certified:
        src = "../image/green-sertifikali-tr.png"
        break

      default:
        src = "../image/green-sertifikali-tr.png"
        break
    }
  }

  return src
}

export const getCertificateStatus = (lang: any, status: any) => {
  const hotelCertificateStatusList = lang
    ? [
        {
          label: "Select All",
          value: hotelCertificateStatusEnum.All,
        },
        {
          label: "Certification Process",
          value: hotelCertificateStatusEnum.CertificationProcess,
        },
        {
          label: "Certified",
          value: hotelCertificateStatusEnum.Certified,
        },
        {
          label: "1st Tier Verified",
          value: hotelCertificateStatusEnum.CertificateApprovedStage1,
        },
        {
          label: "2nd Tier Verified",
          value: hotelCertificateStatusEnum.CertificateApprovedStage2,
        },
        {
          label: "Not considered appropriate",
          value: hotelCertificateStatusEnum.NotConsideredAppropriate,
        },
        {
          label: "Left the process",
          value: hotelCertificateStatusEnum.LeftTheProcess,
        },
        {
          label: "Expired",
          value: hotelCertificateStatusEnum.Expired,
        },
      ]
    : [
        {
          label: "Tümünü Seç",
          value: hotelCertificateStatusEnum.All,
        },
        {
          label: "Belgelendirme Sürecinde",
          value: hotelCertificateStatusEnum.CertificationProcess,
        },
        {
          label: "1. Aşama Belge Verildi",
          value: hotelCertificateStatusEnum.CertificateApprovedStage1,
        },
        {
          label: "2. Aşama Belge Verildi",
          value: hotelCertificateStatusEnum.CertificateApprovedStage2,
        },

        {
          label: "Sertifika Verildi",
          value: hotelCertificateStatusEnum.Certified,
        },
        {
          label: "Uygun Görülmedi",
          value: hotelCertificateStatusEnum.NotConsideredAppropriate,
        },
        {
          label: "Süreçten Ayrıldı",
          value: hotelCertificateStatusEnum.LeftTheProcess,
        },
        { label: "Süresi Bitti", value: hotelCertificateStatusEnum.Expired },
      ]

  return hotelCertificateStatusList.find((x) => x.value === status)?.label
}
