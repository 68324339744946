import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

export default class ErrorNotFound extends React.Component<RouteComponentProps> {
    render() {
        return (
            <React.Fragment>

                <div className="container">
                    <aside className="errorContent">
                        <img src="../image/404icon.svg" alt=""></img>
                        <section className="box1">404</section>
                        <section className="box2"><span>Sonuç bulunamadı</span> <label>(Not found)</label> </section>
                    </aside>
                </div>
            </React.Fragment>
        );
    }
}
