import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from 'redux';
import { setFormData, setFormErrors } from 'src/store/form/actions';

import { getId } from "../../store/thunkCertificateAppeal";


interface OwnProps {

  certificateGuid: string;

}

interface OwnState {
  lang: boolean;
  responsefinish: boolean;
}


function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators({
      setFormData,
      setFormErrors,
      getId,
    }, dispatch)
  };
}



type TgaProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;


class Tga extends Component<TgaProps, OwnState> {

  state = {
    lang: true,
    responsefinish: false
  }




  render() {


    return (

      <img style={{ width: '100%' }} src="../../image/hava.png" alt=""/>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tga);